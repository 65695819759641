import React, {FC, useEffect, useState} from 'react';
import {Typography} from 'antd';
import {API, getFarmsResponse} from '../../api';
import {FarmsList} from '../../components/farms-list/FarmsList';

interface FarmsPageProps {}

export const FarmsPage: FC<FarmsPageProps> = () => {
    const [data, setData] = useState<getFarmsResponse | undefined>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        
        API.getInternalFarms().then(data => {
            setData(data as getFarmsResponse);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    return (
        <div className="farms-page">
            <Typography.Title level={3}>Farms</Typography.Title>
            <FarmsList
                data={data?.farms}
                loading={loading}
            />
        </div>
    );
};
