import React, {FC, useEffect, useState} from "react";
import {API, getInternalNodesResponse } from "../../api";
import {Typography} from "antd";
import {NodesList} from "../../components/nodes-list/NodesList";


interface Props {}

export const NodesPage: FC<Props> = () => {
    const [data, setData] = useState<getInternalNodesResponse | undefined>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true)

        API.getInternalNodes().then(data => {
            setData(data as getInternalNodesResponse);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    return (
        <div className="nodes-page">
            <Typography.Title level={3}>Nodes</Typography.Title>
            <NodesList
                data={data?.nodes}
                loading={loading}
            />
        </div>
    );
}
