import React, {FC, useMemo} from 'react';
import {Table} from 'antd';
import {useNavigate} from 'react-router-dom';
import type {ColumnsType} from 'antd/es/table';
import {FarmListItem, FarmTableData} from '../../api';
import {localeTableSorter, onFilterTableHandler, getTableFilters} from '../../common/utils';
import {MAIN_URLS, SEARCH_PARAMS} from '../../URLs';
import './FarmsList.css';

interface FarmsListProps {
    data?: FarmListItem[]
    loading?: boolean
}

export const FarmsList: FC<FarmsListProps> = ({data, loading}) => {
    const navigate = useNavigate();

    const tableData: FarmTableData[] = useMemo(() => {
        if (!data) {
            return [];
        };

        return data.map(({address, id, name, project}) => {
            return {
                farmName: name,
                projectName: project?.name || '',
                country: address?.countryCode || '',
                farmId: id,
                createdAtTime: address?.dateTime.toLocaleString() || ''
            };
        });
    }, [data]);

    const columns: ColumnsType<FarmTableData> = useMemo(() => [
        {
            title: 'Farm',
            dataIndex: 'farmName',
            filterSearch: true,
            filters: getTableFilters<FarmTableData>(tableData, 'farmName'),
            onFilter: onFilterTableHandler<FarmTableData>('farmName'),
            sorter: localeTableSorter<FarmTableData>('farmName'),
        },
        {
            title: 'Project',
            dataIndex: 'projectName',
            filterSearch: true,
            filters: getTableFilters<FarmTableData>(tableData, 'projectName'),
            onFilter: onFilterTableHandler<FarmTableData>('projectName'),
            sorter: localeTableSorter<FarmTableData>('projectName')
        },
        {
            title: 'Country',
            dataIndex: 'country',
            filterSearch: true,
            filters: getTableFilters<FarmTableData>(tableData, 'country'),
            onFilter: onFilterTableHandler<FarmTableData>('country'),
            sorter: localeTableSorter<FarmTableData>('country')
        },
        {
            title: 'Date of Creating',
            dataIndex: 'createdAtTime',
            filterSearch: true,
            filters: getTableFilters<FarmTableData>(tableData, 'createdAtTime'),
            onFilter: onFilterTableHandler<FarmTableData>('createdAtTime'),
            sorter: localeTableSorter<FarmTableData>('createdAtTime')
        }
    ], [tableData]);

    return (
        <div className="farms-list__container">
            <Table
                loading={loading}
                pagination={{
                    pageSize: 20
                }}
                columns={columns}
                dataSource={tableData}
                rowKey="farmId"
                onRow={({farmId}) => {
                    return {
                        onClick: () => farmId && navigate(`/${MAIN_URLS.FARMS}?${SEARCH_PARAMS.FARM_ID}=${farmId}`),
                        className: farmId ? '' : 'cultivations-list__row--not-clickable'
                    };
                }}
                rowClassName="cultivations-list__row"
            />
        </div>
    );
}
