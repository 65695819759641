import {initializeApp, FirebaseApp, FirebaseError, FirebaseOptions} from 'firebase/app';
import {getAuth, Auth, GoogleAuthProvider} from 'firebase/auth';
import {API} from '.'

export const initFirebase = async (): Promise<Auth | FirebaseError> => {
    const firebaseConfig = await API.getFireBaseConfig();

    const app: FirebaseApp = initializeApp(firebaseConfig as FirebaseOptions);
    const auth: Auth = getAuth(app);

    return auth
};

export const googleAuth = new GoogleAuthProvider()
googleAuth.setCustomParameters({ prompt: 'select_account' });
