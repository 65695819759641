import {MapContainer, TileLayer, Marker, Popup, useMap} from 'react-leaflet'

import React, {useEffect, useState} from "react";
import {API, getInternalWeatherStationsResponse} from "../../api";
import './styles.css'
import {LatLngExpression} from "leaflet";
import {WeatherStationInternalListItem} from "../../api/internal_types";

export const WeatherStationsMapPage = () => {
    const center = [47.78363463526379, 9.250488281250002] as LatLngExpression;
    const [filter, setFilter] = useState('')

    return (<>
        <h5>WeatherStation Map</h5>
        <input type={"radio"} name={"filter"} checked={filter === ''} onClick={() => setFilter('')}/>&nbsp; All<br/>
        <input type={"radio"} name={"filter"} checked={filter === 'mediumbattery'} onClick={() => setFilter('mediumbattery')}/>&nbsp; Medium Battery (30)<br/>
        <input type={"radio"} name={"filter"} checked={filter === 'lowbattery'} onClick={() => setFilter('lowbattery')}/>&nbsp; Low Battery (10)<br/>

        <MapContainer center={center} zoom={4} scrollWheelZoom={true} style={{width: "100%", height: 800}}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MyMarkers filter={filter}/>
        </MapContainer>
    </>)
}

interface MyMarkersProps {
    filter: string
}

const MyMarkers = ({filter}: MyMarkersProps) => {
    const [data, setData] = useState<getInternalWeatherStationsResponse | undefined>();
    const [weatherStations, setWeatherStations] = useState([] as WeatherStationInternalListItem[]);
    const [markers, setMarkers] = useState([] as JSX.Element[])

    useEffect(() => {
        API.getInternalWeatherStations().then(data => {
            setData(data as getInternalWeatherStationsResponse);
        });
    }, []);

    useEffect(() => {
        if (data !== undefined) {
            const w = (data!!.weatherStations || [] as WeatherStationInternalListItem[])
                .filter((weatherStation) => weatherStation.geoLocation?.longitude !== undefined)
                .filter((weatherStation) => weatherStation.geoLocation?.latitude !== undefined)

            setWeatherStations(w)
        }
    }, [data, setWeatherStations]);

    useEffect(() => {
        let filtered = weatherStations;
        switch (filter) {
            case 'lowbattery':
                filtered = weatherStations.filter((it) => it.battery < 10)
                break;
            case 'mediumbattery':
                filtered = weatherStations.filter((it) => it.battery < 30)
                break;
            default:
                break;
        }
            const m = filtered
                .map((weatherStation) => {
                    return <Marker key={weatherStation.id} position={[weatherStation.geoLocation!!.latitude, weatherStation.geoLocation!!.longitude]}>
                        <MyPopup weatherStation={weatherStation}/>
                    </Marker>
                });
            setMarkers(m)

    }, [filter, weatherStations, setMarkers]);

    useMap()

    return (<>
        {markers}
    </>)
}

interface MyPopupProps {
    weatherStation: WeatherStationInternalListItem
}

const MyPopup = ({weatherStation}: MyPopupProps) => {
    return (<Popup>
        <div>id: {weatherStation.id}</div>
        <div>farm: {weatherStation.farm.name}</div>
        <div>battery: {weatherStation.battery}%</div>
        {weatherStation.firmwareVersion && <div>fw: {weatherStation.firmwareVersion}</div>}
        {weatherStation.onomondo && <div>tags: {weatherStation.onomondo?.tags}</div>}
    </Popup>)
}
