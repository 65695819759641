import React, { FC, useMemo } from 'react'
import { BatteryDepletionOTA, OtaTableData } from '../../api'
import { ColumnsType } from 'antd/es/table'
import { Table } from 'antd'
import { getTableFilters, localeTableSorter, majorFirmwareVersions, onFilterTableHandler } from '../../common/utils'

interface BatteryDepletionsOTAProps {
  data?: BatteryDepletionOTA[]
  loading?: boolean
}

export const BatteryDepletionsOTA: FC<BatteryDepletionsOTAProps> = ({ data, loading }) => {
  const tableData: OtaTableData[] = useMemo(() => {
    if (!data) {
      return []
    }

    return data.map(item => {
      return {
        prevVersion: item.prevVersion,
        nextVersion: item.nextVersion,
        batteryDepletion: item.batteryDepletion,
      }
    })
  }, [data])

  const columns: ColumnsType<OtaTableData> = [
    {
      title: 'Previous version',
      dataIndex: 'prevVersion',
      sorter: localeTableSorter<OtaTableData>('prevVersion'),
      filters: getTableFilters<OtaTableData>(tableData, 'prevVersion'),
      onFilter: onFilterTableHandler<OtaTableData>('prevVersion'),
      defaultSortOrder: 'ascend',
      defaultFilteredValue: majorFirmwareVersions,
    },
    {
      title: 'Next version',
      dataIndex: 'nextVersion',
      sorter: localeTableSorter<OtaTableData>('nextVersion'),
      filters: getTableFilters<OtaTableData>(tableData, 'nextVersion'),
      onFilter: onFilterTableHandler<OtaTableData>('nextVersion'),
      defaultSortOrder: 'ascend',
      defaultFilteredValue: majorFirmwareVersions,
    },
    {
      title: 'Battery Depletion',
      dataIndex: 'batteryDepletion',
    },
  ]

  return (
    <div className='battery-depletions-ota__container'>
      <h3 className='battery-depletions-ota__title'>Battery Depletions OTA</h3>
      <Table
        loading={loading}
        pagination={{ pageSize: 20 }}
        columns={columns}
        dataSource={tableData}
        rowClassName='battery-depletions-ota__row'
      />
    </div>
  )
}