import React, {FC, useEffect, useState} from 'react';
import {Typography} from 'antd';
import { API, getFirmwareStatisticsResponse } from '../../api'
import { FirmwareReports } from '../../components/firmware-reports/FirmwareReports'
import {BatteryDepletionsOTA} from '../../components/battery-depletions-ota/BatteryDepletionsOTA'

interface FirmwaresPageProps {}

export const FirmwaresPage: FC<FirmwaresPageProps> = () => {
  const [data, setData] = useState<getFirmwareStatisticsResponse | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    API.getFirmwareStatistics().then(data => {
      setData(data as getFirmwareStatisticsResponse);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  return (
    <div className="firmwares-page">
      <Typography.Title level={3}>Firmwares</Typography.Title>
      <FirmwareReports data={data?.firmwareStatistics.firmwareStats} loading={loading} />
      <BatteryDepletionsOTA data={data?.firmwareStatistics.batteryDepletionOTA} loading={loading} />
    </div>
  );
};
