import React from 'react';
import {Spin, Row} from 'antd';

export const LargeSpinner = () => {
    return (
        <Row style={{height: '100vh', }} align='middle' justify='center'>
            <Spin size='large' />
        </Row> 
    );
}
