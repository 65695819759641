import React, {FC, useMemo} from 'react';
import {Table} from 'antd';
import {useNavigate} from 'react-router-dom';
import type {ColumnsType} from 'antd/es/table';
import {localeTableSorter, onFilterTableHandler, getTableFilters} from '../../common/utils';
import './styles.css';
import {NodeInternalListItem} from "../../api/internal_types";


interface NodeTableData {
    nodeId: string
    battery: string
    firmwareVersion: string
    tags: string
}

interface Props {
    data?: NodeInternalListItem[]
    loading?: boolean
}

export const NodesList: FC<Props> = ({data, loading}) => {
    const navigate = useNavigate();

    const tableData: NodeTableData[] = useMemo(() => {
        if (!data) {
            return []
        }

        return data.map(({id, firmwareVersion = "unknown", onomondo = { tags: [] }, battery }) => {
            return {
                nodeId: id,
                battery: battery + '%',
                firmwareVersion: firmwareVersion,
                tags: onomondo.tags.join(","),
            };
        });
    }, [data]);

    const columns: ColumnsType<NodeTableData> = useMemo(() => [
        {
            title: 'Node',
            dataIndex: 'nodeId',
            filterSearch: true,
            filters: getTableFilters<NodeTableData>(tableData, 'nodeId'),
            onFilter: onFilterTableHandler<NodeTableData>('nodeId'),
            sorter: localeTableSorter<NodeTableData>('nodeId'),
        },
        {
            title: 'Battery',
            dataIndex: 'battery',
            // filterSearch: false,
            // sorter: localeTableSorter<NodeTableData>('battery'),
        },
        {
            title: 'Version',
            dataIndex: 'firmwareVersion',
            filterSearch: true,
            filters: getTableFilters<NodeTableData>(tableData, 'firmwareVersion'),
            onFilter: onFilterTableHandler<NodeTableData>('firmwareVersion'),
            sorter: localeTableSorter<NodeTableData>('firmwareVersion'),
        },
        {
            title: 'Tags',
            dataIndex: 'tags',
            filterSearch: true,
            filters: getTableFilters<NodeTableData>(tableData, 'tags'),
            onFilter: onFilterTableHandler<NodeTableData>('tags'),
            sorter: localeTableSorter<NodeTableData>('tags'),
        },

    ], [tableData]);

    return (
        <div className="nodes-list__container">
            <Table
                loading={loading}
                pagination={{
                    pageSize: 20
                }}
                columns={columns}
                dataSource={tableData}
                rowKey="nodeId"
                onRow={({nodeId}) => {
                    return {
                        onClick: () => nodeId && navigate(`/todo`),
                        // className: cultivationId ? '' : 'nodes-list__row--not-clickable'
                    };
                }}
                rowClassName="nodes-list__row"
            />
        </div>
    );
}
