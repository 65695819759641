import {MapContainer, TileLayer, Marker, Popup, useMap} from 'react-leaflet'

import React, {useEffect, useState} from "react";
import {API, getInternalNodesResponse} from "../../api";
import './styles.css'
import {LatLngExpression} from "leaflet";
import {NodeInternalListItem} from "../../api/internal_types";

export const NodesMapPage = () => {
    const center = [47.78363463526379, 9.250488281250002] as LatLngExpression;
    const [filter, setFilter] = useState('')

    return (<>
        <h5>Node Map</h5>
        <input type={"radio"} name={"filter"} checked={filter === ''} onClick={() => setFilter('')}/>&nbsp; All<br/>
        <input type={"radio"} name={"filter"} checked={filter === 'mediumbattery'} onClick={() => setFilter('mediumbattery')}/>&nbsp; Medium Battery (30)<br/>
        <input type={"radio"} name={"filter"} checked={filter === 'lowbattery'} onClick={() => setFilter('lowbattery')}/>&nbsp; Low Battery (10)<br/>

        <MapContainer center={center} zoom={4} scrollWheelZoom={true} style={{width: "100%", height: 800}}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MyNodeMarkers filter={filter}/>
        </MapContainer>
    </>)
}

interface MyNodeMarkersProps {
    filter: string
}

const MyNodeMarkers = ({filter}: MyNodeMarkersProps) => {
    const [data, setData] = useState<getInternalNodesResponse | undefined>();
    const [nodes, setNodes] = useState([] as NodeInternalListItem[]);
    const [markers, setMarkers] = useState([] as JSX.Element[])

    useEffect(() => {
        API.getInternalNodes().then(data => {
            setData(data as getInternalNodesResponse);
        });
    }, []);

    useEffect(() => {
        if (data !== undefined) {
            const n = data!!.nodes
                .filter((node) => node.geoLocation?.longitude !== undefined)
                .filter((node) => node.geoLocation?.latitude !== undefined)

            setNodes(n)
        }
    }, [data, setNodes]);

    useEffect(() => {
        let filtered = nodes;
        switch (filter) {
            case 'lowbattery':
                filtered = nodes.filter((it) => it.battery < 10)
                break;
            case 'mediumbattery':
                filtered = nodes.filter((it) => it.battery < 30)
                break;
            default:
                break;
        }
            const m = filtered
                .map((node) => {
                    return <Marker key={node.id} position={[node.geoLocation!!.latitude, node.geoLocation!!.longitude]}>
                        <MyPopup node={node}/>
                    </Marker>
                });
            setMarkers(m)

    }, [filter, nodes, setMarkers]);

    useMap()

    return (<>
        {markers}
    </>)
}

interface MyPopupProps {
    node: NodeInternalListItem
}

const MyPopup = ({node}: MyPopupProps) => {
    return (<Popup>
        <div>id: {node.id}</div>
        <div>farm: {node.farm.name}</div>
        <div>battery: {node.battery}%</div>
        {node.firmwareVersion && <div>fw: {node.firmwareVersion}</div>}
        {node.onomondo && <div>tags: {node.onomondo?.tags}</div>}
    </Popup>)
}
