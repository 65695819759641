import React, { FC, useMemo } from 'react'
import { FirmwareStats, FirmwareTableData } from '../../api'
import { ColumnsType } from 'antd/es/table'
import { Table, Tooltip } from 'antd'
import {
  convertNanoToHours,
  convertNanoToMinutes,
  getTableFilters,
  localeTableSorter, majorFirmwareVersions,
  onFilterTableHandler,
} from '../../common/utils'

interface FirmwareReportsProps {
  data?: FirmwareStats[]
  loading?: boolean
}

export const FirmwareReports: FC<FirmwareReportsProps> = ({ data, loading }) => {
  const tableData: FirmwareTableData[] = useMemo(() => {
    if (!data) {
      return []
    }

    return data.map((item) => {
      return {
        firmwareVersion: item.firmwareVersion,
        countDeployment: item.countDeployment,
        averageBatteryDepletion: item.averageBatteryDepletion,
        countMeasurement: item.countMeasurement,
        countEmptyMeasurement: item.countEmptyMeasurement,
        countInvalidMeasurement: item.countInvalidMeasurement,
        countNetworkRegistration: item.countNetworkRegistration,
        countNetworkDeregistration: item.countNetworkDeregistration,
        countNetworkAuthentication: item.countNetworkAuthentication,
        countNetwork2G: item.countNetwork2G,
        countNetwork2G3G: item.countNetwork2G3G,
        countNetwork4G: item.countNetwork4G,
        countNetworkGSM: item.countNetworkGSM,
        countNetworkSwitch: item.countNetworkSwitch,
        countNetworkProviderSwitch: item.countNetworkProviderSwitch,
        averageRegistrationDuration: item.averageRegistrationDuration,
        averageRegistrationInterval: item.averageRegistrationInterval,
      }
    })
  }, [data])

  const columns: ColumnsType<FirmwareTableData> = useMemo(() => [
    {
      title: 'Firmware Version',
      dataIndex: 'firmwareVersion',
      fixed: 'left',
      sorter: localeTableSorter<FirmwareTableData>('firmwareVersion'),
      filters: getTableFilters<FirmwareTableData>(tableData, 'firmwareVersion'),
      onFilter: onFilterTableHandler<FirmwareTableData>('firmwareVersion'),
      defaultSortOrder: 'ascend',
      defaultFilteredValue: majorFirmwareVersions,
    },
    {
      title: (
        <Tooltip title='Average number of deployments per day when sensors are installed. Ideally should be 0.'>
          # of Deployments
        </Tooltip>
      ),
      dataIndex: 'countDeployment',
      render: value => Number.isInteger(value) ? value : value.toFixed(4),
    },
    {
      title: (
        <Tooltip title='Average daily battery depletion when sensors are installed.'>
          Average Battery Depletion
        </Tooltip>
      ),
      dataIndex: 'averageBatteryDepletion',
      render: value => Number.isInteger(value) ? value : value.toFixed(4),
    },
    {
      title: (
        <Tooltip title='Average daily number of measurements when sensors are installed.'>
          # of Measurements
        </Tooltip>
      ),
      dataIndex: 'countMeasurement',
      render: value => Number.isInteger(value) ? value : value.toFixed(4),
    },
    {
      title: (
        <Tooltip title='Average daily number of empty measurements when sensors are installed.'>
          # of Empty Measurements
        </Tooltip>
      ),
      dataIndex: 'countEmptyMeasurement',
      render: value => Number.isInteger(value) ? value : value.toFixed(4),
    },
    {
      title: (
        <Tooltip title='Average daily number of invalid measurements when sensors are installed.'>
          # of Invalid Measurements
        </Tooltip>
      ),
      dataIndex: 'countInvalidMeasurement',
      render: value => Number.isInteger(value) ? value : value.toFixed(4),
    },
    {
      title: (
        <Tooltip title='Average daily number of network registrations when sensors are installed.'>
          # of Network Registrations
        </Tooltip>
      ),
      dataIndex: 'countNetworkRegistration',
      render: value => Number.isInteger(value) ? value : value.toFixed(4),
    },
    {
      title: (
        <Tooltip title='Average daily number of network deregistrations when sensors are installed.'>
          # of Network Deregistrations
        </Tooltip>
      ),
      dataIndex: 'countNetworkDeregistration',
      render: value => Number.isInteger(value) ? value : value.toFixed(4),
    },
    {
      title: (
        <Tooltip title='Average daily number of network authentications when sensors are installed.'>
          # of Network Authentications
        </Tooltip>
      ),
      dataIndex: 'countNetworkAuthentication',
      render: value => Number.isInteger(value) ? value : value.toFixed(4),
    },
    {
      title: (
        <Tooltip title='Average daily number of 2G network connections when sensors are installed.'>
          # of Network 2G
        </Tooltip>
      ),
      dataIndex: 'countNetwork2G',
      render: value => Number.isInteger(value) ? value : value.toFixed(4),
    },
    {
      title: (
        <Tooltip title='Average daily number of 2G/3G network connections when sensors are installed.'>
          # of Network 2G/3G
        </Tooltip>
      ),
      dataIndex: 'countNetwork2G3G',
      render: value => Number.isInteger(value) ? value : value.toFixed(4),
    },
    {
      title: (
        <Tooltip title='Average daily number of 4G network connections when sensors are installed.'>
          # of Network 4G
        </Tooltip>
      ),
      dataIndex: 'countNetwork4G',
      render: value => Number.isInteger(value) ? value : value.toFixed(4),
    },
    {
      title: (
        <Tooltip title='Average daily number of GSM network connections when sensors are installed.'>
          # of Network GSM
        </Tooltip>
      ),
      dataIndex: 'countNetworkGSM',
      render: value => Number.isInteger(value) ? value : value.toFixed(4),
    },
    {
      title: (
        <Tooltip
          title='Average daily number of network switches when sensors are installed. For example, switch from 2G to 4G'>
          # of Network Switch
        </Tooltip>
      ),
      dataIndex: 'countNetworkSwitch',
      render: value => Number.isInteger(value) ? value : value.toFixed(4),
    },
    {
      title: (
        <Tooltip
          title='Average daily number of network provider switches when sensors are installed. For example, switch from Vadafone to KPN.'>
          # of Network Provider Switch
        </Tooltip>
      ),
      dataIndex: 'countNetworkProviderSwitch',
      render: value => Number.isInteger(value) ? value : value.toFixed(4),
    },
    {
      title: (
        <Tooltip title='The average duration between network registration and deregistration. Ideally around 1 minute.'>
          Average Registration Duration (min)
        </Tooltip>
      ),
      dataIndex: 'averageRegistrationDuration',
      render: value => convertNanoToMinutes(value).toFixed(4),
    },
    {
      // title: 'Average Registration Interval (hour)',
      title: (
        <Tooltip title='The average interval between two network registrations. Ideally around 6 hours.'>
          Average Registration Interval (hour)
        </Tooltip>
      ),
      dataIndex: 'averageRegistrationInterval',
      render: value => convertNanoToHours(value).toFixed(4),
    },
  ], [tableData])

  return (
    <div className='firmware-reports__container'>
      <h3 className='firmware-reports__title'>Firmware Reports</h3>
      <p>Daily statistics for each firmware version</p>
      <Table
        loading={loading}
        pagination={{
          pageSize: 20,
        }}
        columns={columns}
        dataSource={tableData}
        scroll={{ x: 'max-content' }}
        rowKey='firmwareVersion'
        rowClassName='firmware-reports__row'
      />
    </div>
  )
}